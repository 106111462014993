import React, { useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/core";
import { Desktop, Tablet } from "../../constants/responsive";
import Pagination from "../Pagination";
import { useWindowSize } from "react-use";

const localeTitle = {
  en: "title_en",
  "zh-cn": "title_zhans",
  "zh-hk": "title_zhant",
};

const localeSubtitle = {
  en: "subtitle_en",
  "zh-cn": "subtitle_zhans",
  "zh-hk": "subtitle_zhant",
};

const PressPastNews = () => {
  const intl = useIntl();
  const { width: windowWidth } = useWindowSize();
  const data = useStaticQuery(graphql`
    query {
      allPastNews {
        edges {
          node {
            id
            date
            press_image
            title_en
            title_zhans
            title_zhant
            subtitle_en
            subtitle_zhans
            subtitle_zhant
            links {
              link
            }
          }
        }
      }
    }
  `);

  const [selectedPage, setSelectedPage] = useState(1);

  let itemsPerPage = 6;
  if (windowWidth < 1200) {
    itemsPerPage = 4;
  }

  const allPastNews = data.allPastNews.edges
    .map((node) => node.node)
    .sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    });

  const maxPage = Math.ceil(allPastNews.length / itemsPerPage);

  if (selectedPage > maxPage) {
    setSelectedPage(maxPage);
  }

  return (
    <div
      css={css`
        ${Tablet} {
          margin-top: 75px;
        }
        ${Desktop} {
          margin-top: 100px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        `}
      >
        {allPastNews
          .filter((_news, index) => {
            return Math.ceil((index + 1) / itemsPerPage) === selectedPage;
          })
          .map((news) => {
            return (
              <a
                key={news.id}
                href={news.links[0].link}
                target="_blank"
                css={css`
                  flex: 0 0 280px;
                  margin: 25px 50px;
                  ${Tablet} {
                    flex: 0 0 240px;
                    margin: 25px 50px;
                  }
                  ${Desktop} {
                    flex: 0 0 280px;
                    margin: 25px 50px;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;
                    border-radius: 20px;
                    background: #f2f2f2;
                    width: 280px;
                    height: 280px;
                    ${Tablet} {
                      width: 240px;
                      height: 240px;
                    }
                    ${Desktop} {
                      width: 280px;
                      height: 280px;
                    }
                  `}
                >
                  <img
                    src={news.press_image}
                    alt={news[localeTitle[intl.locale]]}
                    css={css`
                      width: 100%;
                      height: 100%;
                      object-fit: scale-down;
                    `}
                  />
                </div>

                <h4
                  css={css`
                    margin-bottom: 10px;
                    font-weight: bold;
                  `}
                >
                  {news[localeTitle[intl.locale]]}
                </h4>
                <div>
                  {news[localeSubtitle[intl.locale]] ||
                    `Missing ${localeSubtitle[intl.locale]}`}
                </div>
              </a>
            );
          })}
        {new Array(itemsPerPage - (allPastNews.length % itemsPerPage))
          .fill(0)
          .map((_val, index) => {
            return (
              <div
                key={index}
                css={css`
                  flex: 0 0 280px;
                  margin: 25px 50px;
                  ${Tablet} {
                    flex: 0 0 240px;
                    margin: 25px 50px;
                  }
                  ${Desktop} {
                    flex: 0 0 280px;
                    margin: 25px 50px;
                  }
                `}
              ></div>
            );
          })}
      </div>
      <Pagination
        value={selectedPage}
        onChange={setSelectedPage}
        max={maxPage}
      />
    </div>
  );
};

export default PressPastNews;
