import React, { useState } from "react";
import { FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/core";
import { mainColor } from "../../constants/colors";
import { Desktop, Tablet } from "../../constants/responsive";
import Pagination from "../Pagination";
// import { Desktop, Tablet } from "../../constants/responsive";

const localeTitle = {
  en: "title_en",
  "zh-cn": "title_zhans",
  "zh-hk": "title_zhant",
};

const localeSubtitle = {
  en: "subtitle_en",
  "zh-cn": "subtitle_zhans",
  "zh-hk": "subtitle_zhant",
};

const PressReleasedNews = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allReleasedNews {
        edges {
          node {
            id
            date
            title_en
            title_zhans
            title_zhant
            subtitle_en
            subtitle_zhans
            subtitle_zhant
            links {
              name
              link
            }
          }
        }
      }
    }
  `);

  const [selectedPage, setSelectedPage] = useState(1);

  const allReleasedNews = data.allReleasedNews.edges
    .map((node) => node.node)
    .sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    });

  const maxPage = Math.ceil(allReleasedNews.length / 3);

  return (
    <div
      css={css`
        margin-top: 50px;
        ${Desktop} {
          margin-top: 100px;
        }
      `}
    >
      <h1
        css={css`
          width: 100%;
          ${Tablet} {
            text-align: center;
          }
        `}
      >
        <FormattedHTMLMessage id={`press.releases`} />
      </h1>
      {allReleasedNews
        .filter((_news, index) => {
          return Math.ceil((index + 1) / 3) === selectedPage;
        })
        .map((news) => {
          return (
            <a
              key={news.id}
              href={news.links[0].link}
              target="_blank"
              css={css`
                display: block;
                margin-bottom: 16px;
              `}
            >
              <h5
                css={css`
                  margin-bottom: 5px;
                  display: block;
                  font-style: italic;
                  font-weight: 300;
                `}
              >
                {news.date}
              </h5>
              <h2
                css={css`
                  margin-bottom: 5px;
                  display: block;
                `}
              >
                {news[localeTitle[intl.locale]]}
              </h2>
              <h5>
                {news[localeSubtitle[intl.locale]] ||
                  `Missing ${localeSubtitle[intl.locale]}`}
              </h5>
            </a>
          );
        })}
      <Pagination
        value={selectedPage}
        onChange={setSelectedPage}
        max={maxPage}
      />
    </div>
  );
};

export default PressReleasedNews;
