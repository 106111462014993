import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Container from '../../components/Container';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import PressReleasedNews from '../../components/PressReleasedNews';
import PressPastNews from '../../components/PressPastNews';

const PressPage = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "press/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout footerBlank>
      <SEO
        title={intl.formatMessage({ id: 'press.title' })}
        description={intl.formatMessage({ id: 'press.description' })}
        keywords={intl.formatMessage({ id: 'press.keywords' })}
      />
      <div
        css={css`
          background: #f2f2f2;
          width: 100%;
          height: 260px;
          border-bottom-left-radius: 50% 10%;
          border-bottom-right-radius: 50% 10%;
          display: flex;
          justify-content: center;
          position: relative;
          overflow: hidden;
          ${Tablet} {
            height: 220px;
            border-bottom-left-radius: 50% 20%;
            border-bottom-right-radius: 50% 20%;
          }
          ${Desktop} {
            border-bottom-left-radius: 50% 20%;
            border-bottom-right-radius: 50% 20%;
            height: 325px;
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            width: 100vw;
            min-width: 1000px;
            transform: translate(0%, 0%);
            ${Tablet} {
              width: 100vw;
              min-width: none;
              transform: translate(0, -10%);
            }
            ${Desktop} {
              width: 100vw;
              /* min-width: none; */
              transform: translate(0%, -10%);
            }
          `}
        >
          <Img
            fluid={data.banner.childImageSharp.fluid}
            imgStyle={{ objectFit: 'fill' }}
            loading='eager'
          />
        </div>
        <div
          css={css`
            position: absolute;
            width: 100vw;
            height: 100%;
            min-width: 320px;
            background: linear-gradient(
              115deg,
              rgba(0, 0, 0, 0.9) 0%,
              rgba(0, 0, 0, 0.7) 50%,
              rgba(0, 0, 0, 0) 100%
            );
            ${Tablet} {
              background: linear-gradient(
                115deg,
                rgba(0, 0, 0, 0.9) 0%,
                rgba(0, 0, 0, 0.7) 50%,
                rgba(0, 0, 0, 0) 100%
              );
            }
            ${Desktop} {
              background: linear-gradient(
                115deg,
                rgba(0, 0, 0, 0.9) 0%,
                rgba(0, 0, 0, 0.7) 50%,
                rgba(0, 0, 0, 0) 100%
              );
              width: 100vw;
              height: 100%;
              min-width: 1920px;
            }
          `}
        ></div>
        <Container
          css={css`
            z-index: 1;
          `}
        >
          <div
            css={css`
              padding-top: 115px;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              color: white;
              ${Tablet} {
                padding-top: 80px;
              }
              ${Desktop} {
                padding-top: 120px;
              }
            `}
          >
            <h1>
              <FormattedHTMLMessage id='press.banner.title' />
            </h1>
          </div>
        </Container>
      </div>
      <Container
        css={css`
          margin-top: 50px;
          ${Desktop} {
            margin-top: 50px;
          }
        `}
      >
        <PressPastNews />
        <PressReleasedNews />
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 100px;
            ${Desktop} {
              margin-top: 200px;
            }
          `}
        >
          <div
            css={css`
              border-radius: 20px;
              background: #f2f2f2;
              padding: 30px 50px;
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <h1>
              <FormattedHTMLMessage id='press.contact' />
            </h1>
            <h5
              css={css`
                margin-bottom: 0px;
                text-align: center;
              `}
            >
              <b>
                <FormattedHTMLMessage id='press.email' />:{' '}
              </b>
              <a href='mailto:info@aqumon.com'>info@aqumon.com</a>
              <br />
              <b>
                <FormattedHTMLMessage id='press.phone' />:{' '}
              </b>
              (852) 3590 9951
              <br />
              <FormattedHTMLMessage id='press.address' />
            </h5>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default PressPage;
